import { useEffect, useState } from 'react';
import * as React from 'react';
import ReactGA from 'react-ga4';

// components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { CssTextField } from '../milestones/components/CssTextField';
import Alerts from '../../../shared/Alerts';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// data
import { data } from '../../../../config/data';

import { color } from '../../../../style/color';
import { style } from '../../../../style/style';
import { form } from '../../../../resources/form-data';
import { styled } from '@mui/material/styles';
import { postMessage } from '../../../../services/MessageService';
import { getMessages } from '../../../../services/MessageService';
import { WebServiceClient, getAccessToken } from "../../../../services/WebServiceClient";
import { LocalStorageService } from "../../../../services/LocalStorageService";


import * as moment from "moment";

const CssButtonDarkBlue = styled(Button)({
  '& .MuiButton-root': {
    backgroundColor: color.brandDarkBlue,
    color: 'white',
    width: 'fit-content',
    fontSize: '16px',
    textTransform: 'unset',
    borderRadius: '10px',
    '&:hover fieldset': {
      backgroundColor: color.brandLightBlue,
    },
  },
});

export default function ViewMessages(props) {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState({});
  const [messages, setMessages] = useState([]);
  const [records, setRecords] = useState([]);

  const token = getAccessToken();
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const user = JSON.parse(LocalStorageService.get("user"));

  const getRecords = () => {
    const config = {
      headers: { Authorization: token },
    };
    const params = {
      orgId: org.id,
      siteId: null,
      where: {
        owner: user.id,
      },
    };
    WebServiceClient.get("/record", params, config)
      .then(function (response) {
        if (response.data) {
          let records = response.data.map((r) => {
            return {
              type: r.description,
              fileName: r.fileName,
              date: r.createdAt,
              url: r.fileUrl,
            };
          });
          setRecords(records);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getRecords();
  }, []);

  useEffect(() => {
    (async () => {
      let thisMessages = await getMessages(props.mortgage.Mortgage.id);

      // if DocumentName matches the name of the document, set the document
      for (let i = 0; i < thisMessages.length; i++) {
        let message = thisMessages[i];
        let matchingRecord = records.find(record => record.fileName === message.DocumentName);
        if (matchingRecord) {
          thisMessages[i].DocumentUrl = matchingRecord.url;
        }
      }

      // sort by the date 
      thisMessages.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) });

      setMessages(thisMessages);

    })();
  }, [records]);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    
    return (
      <React.Fragment>
         <TableRow
            key={row.name}
            sx={{ '& > *': { borderBottom: 'unset'  } }}
          >
            <TableCell component="th" scope="row">
              {row.MessageSubject}
            </TableCell>
            <TableCell>{moment(row.createdAt).format('MMM Do YYYY, h:mm:ss a')}</TableCell>
            <TableCell>
              {row?.FromIntegrar > 0 ? 'Received': 'Sent'}

              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>

            </TableCell>
          </TableRow>
   
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} ><Collapse in={open} timeout="auto" unmountOnExit>Original Message</Collapse></TableCell>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box style={{ padding: '20px 0'}}>
                  <Typography style={style.bodyText}>
                    {row.MessageBody}
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>

          {row?.DocumentUrl && <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}><Collapse in={open} timeout="auto" unmountOnExit>Reply from Integrar</Collapse></TableCell>
            <TableCell  style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box style={{ padding: '20px 0'}}>
                <CssButtonDarkBlue
                        onClick={() => window.open(row.DocumentUrl, '_blank')}
                      >
                        View Reply
                      </CssButtonDarkBlue>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>}
   
{/*    
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}><Collapse in={open} timeout="auto" unmountOnExit>Reply from Integrar</Collapse></TableCell>
            <TableCell  style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box style={{ padding: '20px 0'}}>
                  <Typography style={style.bodyText}>
                    {row.FromIntegrar.toString()}
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow> */}

      </React.Fragment>
    );
  }

  return (<>

    <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: color.brandDarkBlue }}>
          <TableRow>
            <TableCell sx={{ color: color.white, fontWeight: 'bold' }}>Subject</TableCell>
            <TableCell sx={{ color: color.white, fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ color: color.white, fontWeight: 'bold' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </>);
}
