import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

// components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SendMessage from './messages/SendMessage';
import ViewMessages from './messages/ViewMessages';

// data
import { data } from '../../../config/data';

import { color } from '../../../style/color';
import { style } from '../../../style/style';
import { form } from '../../../resources/form-data';
import { styled } from '@mui/material/styles';

const CssButtonDarkBlue = styled(Button)({
  '& .MuiButton-root': {
    backgroundColor: color.brandDarkBlue,
    color: 'white',
    width: 'fit-content',
    fontSize: '16px',
    textTransform: 'unset',
    borderRadius: '10px',
    '&:hover fieldset': {
      backgroundColor: color.brandLightBlue,
    },
  },
});

export default function HelpSupport(props) {
  const [subject, setSubject] = useState();
  const [phone, setPhone] = useState('0113 513 5375');
  const [showMessageForm, setShowMessageForm] = useState(false);

  useEffect(() => {
    (async () => {
      if (props.mortgage.Mortgage) {
        setSubject('Reference ' + props.mortgage.Mortgage.RegMatterReference);
        if (
          data.phones.some(
            p =>
              p.key ===
              props.mortgage.Mortgage?.MatterAdminDetails?.EntityReference,
          )
        ) {
          setPhone(
            data.phones.find(
              p =>
                p.key ===
                props.mortgage.Mortgage.MatterAdminDetails.EntityReference,
            ).value,
          );
        }
      }
    })();
  }, [props.mortgage]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/help-and-support' });
  }, []);

  return (
    <Paper style={style.remortgage}>
      <Grid container>
        <Grid item xs={12}>
          
          {!showMessageForm && <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            textAlign="left"
          >
            {props.mortgage.Mortgage && (
              <Typography style={style.bodyBoldTitle} sx={{ paddingTop: '0' }}>
                {form.help_support.title_label}{' '}
                {props.mortgage.Mortgage.RegMatterReference}
              </Typography>
            )}
            <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
              <Typography style={style.bodyBold} sx={{ paddingTop: '10px' }}>
                {form.help_support.return_label}
              </Typography>
              <Typography style={style.bodyText} sx={{ paddingTop: '10px' }}>
                Integrar
              </Typography>
              <Typography style={style.bodyText}>
                Majestic, City Square
              </Typography>
              <Typography style={style.bodyText}> Leeds</Typography>
              <Typography style={style.bodyText}> LS1 2EF</Typography>
              <Typography style={style.bodyText} sx={{ paddingTop: '10px' }}>
                <Box component="span" style={style.bodyBold}>
                  {form.help_support.email_label}
                </Box>
                remortgage@integrar.services
              </Typography>
              {props.mortgage.Mortgage && (
                <Typography style={style.bodyText} sx={{ padding: '10px 0' }}>
                  {form.help_support.quote_label}
                  {': '}
                  {props.mortgage.Mortgage.RegMatterReference}
                </Typography>
              )}
               <Box>
                <Typography
                    variant="body2"
                    style={style.bodyItalics}
                    sx={{ paddingTop: '10px' }}
                  >
                    {form.help_support.send_description}
                  </Typography>
              </Box>

             {/*<CssButtonDarkBlue
                type="submit"
                variant="contained"
                // color="button"
                sx={{...style.darkBlueButton, ...{ marginTop: '20px' }}}
                // sx={{ backgroundColor: color.brandDarkBlue}}
                href={`mailto:remortgage@integrar.services?subject=${subject}`}
                target="_blank"
              >
                {form.help_support.send_label}
              </CssButtonDarkBlue>*/}


              <CssButtonDarkBlue
                type="submit"
                variant="contained"
                sx={{...style.darkBlueButton, ...{ marginTop: '20px' }}}
                onClick={() => setShowMessageForm(true)}
              >
                {form.help_support.messaging_button}
              </CssButtonDarkBlue>
               
              <ViewMessages mortgage={props.mortgage} />
            </Grid>
          </Stack>}


          {showMessageForm && <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            textAlign="left"
          >
            <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
              <Typography style={style.bodyBoldTitle} sx={{ paddingTop: '0' }}>
                {form.help_support.messaging_title}
              </Typography>
              <Typography
                variant="body2"
                style={style.bodyItalics}
                sx={{ paddingTop: '10px' }}
              >
                {form.help_support.messaging_description}
              </Typography>

              
              <SendMessage mortgage={props.mortgage} onCancel={()=> setShowMessageForm(false)} />

            </Grid>
          </Stack>}



        </Grid>
      </Grid>
    </Paper>
  );
}
